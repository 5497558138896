(function() {
	'use strict';

	var $infoBox = $('.js-question'),
		$window = $(window),
		$animatedGraphs = $('.js-animatedGraph'),
		waypoints = avm.require('avast.web.waypoints');

		$window.on('resize scroll', function() {
			if (waypoints($animatedGraphs, 300, 300, false)) {
				$animatedGraphs.addClass('animated');
			}
		})


	// FAQ toggler
	if ( $infoBox.length > 0 ) {
		$infoBox.on('click', function() {
			var $self = $(this),
				$target = $self,
				targetOpened = !!$target.hasClass('expand');

			// Close all boxes
			$infoBox.removeClass('expand');

			// Check current box for toggle
			if (targetOpened) {
				$target.removeClass('expand');
			} else {
				$target.addClass('expand');
			}
		});
	}

	// signal blogs slider
	if ($('.slider-sliderSliNotiSecSm').length > 0) {
		var sliderInfNotiSecMed = tns({
			"container": ".slider-sliderSliNotiSecSm",
			"controlsContainer": ".slider-sliderSliNotiSecSm-controls",
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"controls": false,
			"navPosition": "bottom",
			"mouseDrag": true,
			"speed": 400,
			"center": false,
			"slideBy": 1,
			"fixedWidth": 250,
			"gutter": 16,
			"loop": false,
			"responsive": {
				"768": {
					"slideBy": 1,
					"controls": false,
					"fixedWidth": 316,
					"gutter": 32
				},
				"1280": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				},
				"1600": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				}
			}
		});
	}

})();
